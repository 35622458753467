/* global WOW,lightbox */
/* eslint-env jquery */

'use strict';

jQuery( function( $ ) {

	$( window ).on( 'scroll', function () {

        if ( $( '#masterHeader' ).length ) {
            const headerScrollPos = 120;
            const sticky = $( '#masterHeader' );

            if ( $( window ).scrollTop() > headerScrollPos ) {
                sticky.addClass( 'site-header--sticky' );
            } else if ( $( this ).scrollTop() <= headerScrollPos ) {
                sticky.removeClass( 'site-header--sticky' );
            }
        }
    } );


	if ( $( '.reveal' ).length ) {
        const reveal = new WOW( {
            boxClass:     'reveal',
            animateClass: 'animated',
            mobile:        true,
            live:          true,
        } );
        reveal.init();
    }

	$( '.slider' ).slick( {
		dots: true,
		infinite: true,
		speed: 800,
		centerMode: false,
		variableWidth: true,
		autoplay: true,
		responsive: [
			{
				breakpoint: 992,
				settings: {
					dots: false,
					centerMode: true,
				}
			}
		  ],
	} );

	$( '#sectionParallax' ).jarallax( {
		speed: 0.2,
	} );

	const mobileOnly = window.matchMedia( '(max-width: 768px)' );

	if ( mobileOnly.matches ) {
		// Footer widget toggle
		$( document ).on( 'click', '.site-footer__widget-title', function( event ) {
			event.preventDefault();
			$( this ).toggleClass( 'open' );
			$( this ).siblings( '.site-footer__widget-content' ).slideToggle().toggleClass( 'visible' );
		} );
	}

	// Side Menu toggler
	$( document ).on( 'click', '#toggleMainMenu', function( event ) {
		event.preventDefault();
		const button = $( this );

		if ( button.hasClass( 'open' ) ) {
			$( '#navigationMobile' ).slideToggle();
			button.removeClass( 'open' );
		} else {
			$( '#navigationMobile' ).slideToggle();
			button.addClass( 'open' );
		}
	} );

	$( document ).on( 'click', '.nav-mobile__link--parent', function( e ) {
		e.preventDefault();
		const navItem = $( this ).parent();

		if ( navItem.hasClass( 'nav-mobile__item--visible' ) ) {
			navItem.children( '.nav-mobile__dropdown' ).slideToggle();
			navItem.removeClass( 'nav-mobile__item--visible' );
		} else {
			navItem.children( '.nav-mobile__dropdown' ).slideToggle();
			navItem.addClass( 'nav-mobile__item--visible' );
		}
	} );

	lightbox.option( {
		disableScrolling: true,
		showImageNumberLabel: false,
	} );

	if ( $( '.accordion' ).length ) {
        const  accordion = $( '.accordion' );
		accordion.each( function () {
			const name = $( this ).data( 'name' );
            const self = $( this );
            const item = self.find( '.accordion__item' );
			self.addClass( name );
            self.find( '.accordion__item-content' ).hide();
            self.find( '.active' ).find( '.accordion__item-content' ).show();
            item.each( function () {
                $( this )
                    .find( '.accordion__item-title' )
                    .on( 'click', function () {
                        if ( $( this ).parent().hasClass( 'active' ) === false ) {
                            $( '.accordion.' + name ).find( '.accordion__item' ).removeClass( 'active' );
							$( '.accordion.' + name ).find( '.accordion__item' ).find( '.accordion__item-content' ).slideUp();
                            $( this ).parent().addClass( 'active' );
                            $( this ).parent().find( '.accordion__item-content' ).slideDown();
                        }
                    } );
            } );
        } );
    }

	$( document ).on( 'change', '.calculate', function() {
		const sum = parseInt( $( '#totalPrice' ).html() );
		const value = parseInt( $( this ).val() );
		let total;

		if ( $( this ).is( ':checked' ) ) {
			total = sum + value;
		} else {
			total = sum - value;
		}

		$( '#totalPrice' ).html( total );
	} );

	$( document ).on( 'change', '.voucher, .sms', function() {
		const voucher = parseInt(  $( '.voucher:checked' ).val() );
		const sms = parseInt( $( '.sms:checked' ).val() );
		const total = voucher + sms;

		$( '#totalPrice' ).html( total );
	} );

	$( '#datepicker' ).datepicker( {
		format: 'dd/mm/yyyy',
		language: 'el'
	} );
} );
